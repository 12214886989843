<template>
  <br />
  <div v-if="this.payMethodsDetail.payMethodsDetail">
    <div
      class="flex flex-column justify-content align-items-center"
      v-for="item in this.payMethodsDetail.payMethodsDetail.data"
      :key="item.id"
    >
      <div class="responsive">
        <div class="justify-content align-items-center">
          <div v-if="item.id == 3 && this.continueBinance">
            <h4>
              Paga tus tickets - Escanea con tu
              <b style="color: #fcd535">App de Binance</b>
            </h4>
            <div
              class="text-center"
              v-if="item.id == 3 && this.continueBinance"
            >
              <div class="card card3">
                <img
                  :src="qrRoute + item.bank_data.binance_qr_url"
                  alt="Binance"
                  class="qrImage"
                />
              </div>
              <div>
                <label
                  >Pagando ID
                  <b id="idBinance" style="color: #fcd535">{{
                    item.bank_data.binance_id
                  }}</b></label
                >
                <label v-show="parent_url"></label>
                <button
                  class="pi pi-copy copyButton"
                  @click="copyIdBinance()"
                ></button>
              </div>
              <h4>Ir a Binance</h4>
              <button
                class="continueButton"
                @click="this.continueBinance = !this.continueBinance"
              >
                Continuar
              </button>
            </div>
          </div>
          <div v-if="!this.continueBinance" class="text-center">
            <div>
              <h5>Introduzca el ID de la orden para confirmar pago</h5>
              <InputText
                id="reference"
                class="binancePayReference"
                aria-autocomplete="off"
                autocomplete="off"
                autofocus
                v-model="reference"
                placeholder="ID de la orden *"
              >
              </InputText>
              <div v-if="!reference" v-show="submitted">
                <small class="p-error mb-4">Campo requerido.</small>
              </div>
              <br />
              <br />
              <p class="mt-3">
                TASA DE CAMBIO: {{ calculateConvertAmount(item) }}
              </p>
              <label>Monto a pagar en BUSD: </label>

              <div class="field" v-if="this.amount_to_pay">
                <InputNumber
                  id="amount_to_pay"
                  v-model.number="amount"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  locale="es-VE"
                  autocomplete="off"
                  :max="50000"
                  aria-autocomplete="off"
                  inputmode="numeric"
                  inputClass="label-input"
                  :useGrouping="false"
                />
                <div v-if="!amount" v-show="submitted">
                  <small class="p-error mb-4"
                    >Campo requerido, debe ser mayor que cero.</small
                  >
                </div>
              </div>
              <br />
              <div class="field">
                <label>Fecha de Pago *:</label>
                <div class="field">
                  <InputText
                    id="date"
                    type="date"
                    class="label-input"
                    autocomplete="off"
                    aria-autocomplete="off"
                    autofocus
                    v-model="date"
                    :max="new Date().toISOString().substr(0, 10)"
                  />
                  <div v-if="!date" v-show="submitted">
                    <small class="p-error mb-4">Campo requerido.</small>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <button
              class="continueButton confirmButton ml-6"
              @click="checkPay()"
            >
              Confirmar Pago
            </button>
          </div>

          <div v-if="item.id != 3">
            <div class="flex justify-content-center">
              <div class="flex justify-content-between align-items-center card card2 w-full">
                <div class="justify-content align-items-center">
                  <div
                    v-for="data in pay_method_structure"
                    :key="data.key"
                    class="mb-2"
                  >
                    <h6 v-if="data.label" class="inline" style="color: #cc195a">
                      {{ data.label }}:
                    </h6>
                    <h6
                      v-if="item.bank_data"
                      class="inline ml-2"
                      style="color: #7d7d7d"
                    >
                      {{ item.bank_data[data.key] }}
                    </h6>
                    <!-- <button
                  class="pi pi-copy copyButton"
                  @click="copyId()"
                  ></button> -->
                  </div>
                </div>
                <div
                
              >
                <img
                  class="m-0"
                  src="/images/qrPagoMovil.png"
                  style="width: 130px; height: 130px; max-width: 100%;"
                />
              </div>
              </div>
            </div>
            <div class="reference-responsive grid w-full m-0 p-0">
              <div
                class="justify-content-center align-items-center col-12"
              >
                <label>Código de Referencia *:</label>
                <div class="field">
                  <InputMask
                    id="i_cod_ref"
                    mask="9999"
                    slotChar=""
                    inputmode="numeric"
                    pattern="[0-9]*"
                    v-model="reference"
                    autocomplete="off"
                    aria-autocomplete="off"
                    class="label-input"
                    :useGrouping="false"
                    autofocus
                  />
                  <div v-if="!reference" v-show="submitted">
                    <small class="p-error mb-4">Campo requerido.</small>
                  </div>
                </div>

                <p class="mt-3">
                  TASA DE CAMBIO: {{ calculateConvertAmount(item) }}
                </p>
                <label>Monto a pagar en BUSD:</label>
                <div class="field" v-if="this.amount_to_pay">
                  <InputNumber
                    id="amount_to_pay"
                    v-model.number="payorder_due"
                    disabled="true"
                    mode="decimal"
                    :minFractionDigits="2"
                    :maxFractionDigits="2"
                    locale="es-VE"
                    autocomplete="off"
                    aria-autocomplete="off"
                    inputmode="numeric"
                    inputClass="label-input"
                    :useGrouping="false"
                  />
                  <div v-if="!amount" v-show="submitted">
                    <small class="p-error mb-4"
                      >Campo requerido, debe ser mayor que cero.</small
                    >
                  </div>
                  <div v-if="amount.toString().length >= 16" v-show="submitted">
                    <small class="p-error mb-4"
                      >Debe ser menor a 16 digitos.</small
                    >
                  </div>
                  <!--InputText
                    id="amount_to_pay"
                    class="label-input"
                    autocomplete="off"
                    aria-autocomplete="off"
                    autofocus
                    :disabled="false"
                    v-model="amount"
                  /-->
                </div>
                <label>Monto a pagar en Bolívares:</label>
                <div class="field" v-if="this.amount_to_pay">
                  <InputNumber
                    id="amount_to_pay"
                    disabled="true"
                    :value="
                      (
                        Number(this.payorder_due).toFixed(8) * this.convert(1)
                      ).toFixed(2) + ' BS. '
                    "
                    autocomplete="off"
                    aria-autocomplete="off"
                    inputmode="numeric"
                    inputClass="label-input"
                    :useGrouping="false"
                  />
                  <div v-if="!amount" v-show="submitted">
                    <small class="p-error mb-4"
                      >Campo requerido, debe ser mayor que cero.</small
                    >
                  </div>
                  <div v-if="amount.toString().length >= 16" v-show="submitted">
                    <small class="p-error mb-4"
                      >Debe ser menor a 16 digitos.</small
                    >
                  </div>
                </div>
                <br />
                <label>Fecha de Pago *:</label>
                <div class="field">
                  <InputText
                    id="date"
                    type="date"
                    class="label-input"
                    autocomplete="off"
                    aria-autocomplete="off"
                    autofocus
                    v-model="date"
                    :max="new Date().toISOString().substr(0, 10)"
                    @keydown="blockKeys($event)"
                  />
                  <div v-if="!date" v-show="submitted">
                    <small class="p-error mb-4">Campo requerido.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div class="justify-content-center align-items-center">
        <button
          @click="checkPay()"
          class="btn align-items-center justify-content-center mb-4"
          v-if="item.id != 3"
        >
          Confirma Pago
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import HTTP from "@/service/http";

export default {
  props: ["pay_method_structure", "amount_to_pay"],
  emits: ["registerTransferenciaVes", "successTransferenceVes"],
  data() {
    return {
      pay_method_select: "",
      reference: null,
      date: new Date().toISOString().substr(0, 10), // TODO: se debe conciderar que si son las 23:59 del día actual y el cliente paga 5 minutos desues ya es pago del día siguiente.
      bank_type_selected: "",
      amount: Number(this.amount_to_pay) ? Number(this.amount_to_pay) : null,
      bankData: "",
      content: null,
      continueBinance: true,
      parent_url: process.env.VUE_APP_API_BASE_URL,
      submitted: false,
      payorder_due: null,
      interval: null,
    };
  },
  computed: {
    ...mapState("ticketoffice", [
      "payMethods",
      "payMethodsDetail",
      "depositCreditOrder",
      "binancePayOrder",
      "payOptions",
      "monedaOptions",
    ]),
    ...mapGetters("ticketoffice", ["currentPlayData"]),
    qrRoute() {
      return this.parent_url.substring(0, this.parent_url.length - 4);
    },
  },
  mounted() {
    this.submitted = false;
    this.amount = Number(this.amount_to_pay)
      ? Number(this.amount_to_pay)
      : null;
    this.reNew();
  },
  created() {
    if (this.interval == null) {
      this.interval = setInterval(() => {
        this.reNew();
      }, 3000);
    }
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    reNew() {
      return new Promise((resolve, reject) => {
        HTTP(500000)
          .get(`/payorders/${this.currentPlayData.ticket.serial}`)
          .then((resp) => {
            this.payorder_due = parseFloat(resp.data.data.total_due);
            if (this.payorder_due <= 0) {
              clearInterval(this.interval);
              this.$emit("successTransferenceVes", this.payorder_due);
            }
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    payMethodTypeSelected(pay_method_selected) {
      this.pay_method_select = pay_method_selected;
      this.$emit("payMethodTypeSelected", this.pay_method_select);
    },
    copyIdBinance() {
      this.content = document.createElement("input");
      this.content.setAttribute(
        "value",
        document.getElementById("idBinance").innerHTML
      );
      document.body.appendChild(this.content);
      this.content.select();
      document.execCommand("copy");
      document.body.removeChild(this.content);
      this.$store.dispatch("changeSystemMessage", {
        severity: "success",
        detail: "Se ha copiado el ID.",
        life: 4000,
      });
    },
    checkPay() {
      this.submitted = true;
      if (!this.reference || !this.date || !this.amount) {
        this.$store.dispatch("changeSystemMessage", {
          severity: "error",
          detail: "Ingrese los Datos Solicitados.",
          life: 4000,
        });
      } else {
        this.$emit(
          "registerTransferenciaVes",
          this.reference,
          this.date,
          this.amount
        );
        // this.reference = "";
        // this.date = "";
        // this.$store.dispatch("changeSystemMessage", {
        //   severity: "success",
        //   detail: "Pago Solicitado con Éxito.",
        //   life: 4000,
        // });
      }
    },
    calculateConvertAmount(item) {
      let convert_amount = null;
      this.payOptions.forEach((payOption) => {
        const option = payOption.options.filter(
          (option) => option.id === item.fk_payment_method
        );

        if (option.length > 0)
          convert_amount = `${Number(option[0].convert_amount).toFixed(2)} ${
            option[0].currency_acronym
          } / BUSD`;
      });

      return convert_amount;
    },
    convert(amount) {
      let moneda = this.monedaOptions.find((e) => e.code === 1);
      return !isNaN(amount) && moneda && !isNaN(moneda.exchange_rate)
        ? Number(amount) * Number(moneda.exchange_rate)
        : Number(0);
    },
    blockKeys($event) {
      $event.preventDefault();
    },
  },
};
</script>

<style scoped>
.bcoBtn:hover {
  cursor: pointer;
}

.bcoBtn:hover {
  cursor: pointer;
}
::v-deep .label-input {
  width: 100%;
  max-width: 100%;
  height: 15px;
  color: #7d7d7d;
  background: transparent;
  border-bottom: 1px solid #7d7d7d;
  border-radius: 0px;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
  padding-right: 5px !important;
}
label {
  font-size: 1rem;
  font-weight: 400;
  font-family: "Roboto";
  color: #7d7d7d;
}
.img {
  width: 160px;
}
.btn {
  background: #cc195a;
  border-radius: 14px;
  border: 0rem;
  width: 12rem;
  height: 3rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
.btn:hover {
  cursor: pointer;
}
.logo-banco {
  height: 100px;
}
.card2 {
  background-color: #f3f3f3;
  width: 350px;
  height: auto;
  padding: 5px;
}
.card3 {
  background-color: #ffffff;
  width: fit-content;
  margin-left: 30%;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
}
.qrImage {
  width: 150px;
}
.precarga {
  background: transparent url(/images/loading.jpg) center no-repeat;
  width: 100%;
}

.continueButton {
  background-color: transparent;
  border: 1px solid #cc195a;
  border-radius: 6px;
  width: fit-content;
  color: #cc195a;
  font-weight: 700;
  font-size: 24px;
  width: 200px;
  height: 45px;
}
.continueButton:hover {
  cursor: pointer;
}
.copyButton {
  background-color: transparent;
  border-style: none;
  font-size: large;
  color: #7d7d7d;
}
.copyButton:hover {
  cursor: pointer;
}
.binancePayReference {
  background: #f9f9f9;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 90%;
  height: 36px;
  color: #a3a3a3;
  font-family: "Roboto";
  border-style: none;
  font-weight: 600;
}
.confirmButton {
  border-color: #0da700;
  color: #0da700;
  width: 240px;
}
@media screen and (min-width: 320px) and (max-width: 390px) {
  .card2 {
    width: 330px;
    align-items: center;
    height: fit-content;
    margin-left: 15px;
  }
  .img {
    width: 120px;
  }
  .responsive {
    width: fit-content;
  }
  .reference-responsive {
    margin-left: 15px;
  }
}
</style>
